import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons'
import { useQueryParams } from '@/hooks/use-query-params'
import { cn } from '@/utils/cn'
import { Button } from './ui/button'

type Props = {
  limit: number
  total: number
  className?: string
}

export const Pagination = ({ limit, total, className }: Props) => {
  const [queryParams, setQueryParams] = useQueryParams()

  const page = Number(queryParams.page ?? 1)
  const pages = Math.ceil(total / limit)

  const prev = () =>
    setQueryParams((prev) => ({ ...prev, page: String(page - 1) }))

  const next = () =>
    setQueryParams((prev) => ({ ...prev, page: String(page + 1) }))

  return (
    <div className={cn('flex items-center gap-8', className)}>
      <Button size="sm" variant="outline" onClick={prev} disabled={page === 1}>
        <ArrowLeftIcon className="h-4 w-4" />
      </Button>
      <span className="font-normal text-sm">
        Side <strong className="text-gray-900">{page}</strong> av{' '}
        <strong className="text-gray-900">{pages}</strong>
      </span>
      <Button
        size="sm"
        variant="outline"
        onClick={next}
        disabled={page === pages}
      >
        <ArrowRightIcon className="h-4 w-4" />
      </Button>
    </div>
  )
}
