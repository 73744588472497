import { useEffect } from 'react'
import { useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { QueryError } from '@/components/query-error'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const seasonsDocument = graphql(`
  query Seasons {
    seasons {
      id
      name
    }
  }
`)

type SeasonSelectorProps = {
  selectedSeason: string
  setSelectedSeason: (id: string) => void
}

export const SeasonSelector = ({
  selectedSeason,
  setSelectedSeason,
}: SeasonSelectorProps) => {
  const [{ data, error }] = useQuery({
    query: seasonsDocument,
  })
  const seasons = data?.seasons || []

  useEffect(() => {
    const id = seasons[0]?.id
    if (id && !selectedSeason && id !== selectedSeason) {
      setSelectedSeason(id)
    }
  }, [seasons, selectedSeason])

  return (
    <>
      {error && <QueryError error={error} />}
      {seasons.length > 0 && (
        <Select
          value={selectedSeason}
          onValueChange={(newValue) => setSelectedSeason(newValue)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Sesonger</SelectLabel>
              {seasons.map((season) => (
                <SelectItem key={season.id} value={season.id}>
                  {season.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
    </>
  )
}
