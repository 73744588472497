import { useContext, createContext, ReactNode, useCallback } from 'react'
import { useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { ViewerContextProviderQuery } from '@/__generated__/gql/graphql'
import { Loading } from './loading'
import { QueryError } from './query-error'

export const viewerContextProviderDocument = graphql(`
  query ViewerContextProvider {
    viewer {
      id
      user {
        id
        name
        roles
      }
      canMutateBookings: can(action: "mutate", resource: "Bookings")
    }
  }
`)

type ViewerContext = {
  hasRole: (role: string) => boolean
  hasAnyRole: (...role: string[]) => boolean
  reload: () => void
} & ViewerContextProviderQuery['viewer']

const ViewerContext = createContext<ViewerContext | null>(null)

export function ViewerContextProvider({ children }: { children: ReactNode }) {
  const [{ data, fetching, error }, reload] = useQuery({
    query: viewerContextProviderDocument,
  })

  const hasRole = useCallback(
    (role: string) => {
      return data?.viewer.user?.roles.includes(role) || false
    },
    [data]
  )

  const hasAnyRole = useCallback(
    (...roles: string[]) => {
      return roles.some((role) => hasRole(role))
    },
    [data]
  )

  if (fetching) return <Loading />
  if (error || !data) {
    return <QueryError error={error || new Error('Error loading viewer')} />
  }

  return (
    <ViewerContext.Provider
      value={{ ...data.viewer, hasRole, hasAnyRole, reload }}
    >
      {children}
    </ViewerContext.Provider>
  )
}

export function useViewer() {
  return useContext(ViewerContext) as ViewerContext
}
