import { Link, Outlet } from 'react-router-dom'
import { useQuery } from 'urql'
import { EyeOpenIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { formatDate } from '@/utils/format-date'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { WarningAlert } from '@/components/warning-alert'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { TableLayout } from '@/components/table-layout'
import { Button } from '@/components/ui/button'

const seasonsDocument = graphql(`
  query SeasonsTable {
    seasons {
      id
      name
      registrationOpensAt
      active
    }
  }
`)

export const Seasons = () => {
  const [{ data, fetching, error }, refetch] = useQuery({
    query: seasonsDocument,
  })

  const seasons = data?.seasons || []

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-bold leading-loose">Sesonger</h1>
        <Button asChild>
          <Link to="new">Ny sesong +</Link>
        </Button>
      </div>

      <TableLayout>
        <TableHeader>
          <TableRow>
            <TableHead>#</TableHead>
            <TableHead>Påmeldingsstart</TableHead>
            <TableHead>Aktiv</TableHead>
            <TableHead className="w-[50px]" />
          </TableRow>
        </TableHeader>
        <TableBody>
          {seasons.map((season) => (
            <TableRow key={season.id}>
              <TableCell>{season.name}</TableCell>
              <TableCell>
                {formatDate(season.registrationOpensAt, { style: 'time' })}
              </TableCell>
              <TableCell>{season.active ? 'Ja' : 'Nei'}</TableCell>
              <TableCell>
                <Button asChild variant="link">
                  <Link to={`/seasons/${season.id}`}>
                    <EyeOpenIcon className="h-4 w-4" />
                  </Link>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableLayout>

      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {seasons.length === 0 && !fetching && !error && (
        <WarningAlert
          title="Ingen resultater"
          description="Ingen sesonger ble funnet."
        />
      )}

      <Outlet context={{ refetch }} />
    </>
  )
}
