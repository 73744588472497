import { useState } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { Button } from '@/components/ui/button'
import { WarningAlert } from '@/components/warning-alert'
import { CreateAccountingExportDialog } from '@/components/new-accounting-export-dialog'
import { useViewer } from '@/components/viewer-context-provider'
import { BookingInfo } from './components/booking-info'
import { CancelBookingButton } from './components/cancel-booking-button'
import { ResendMailDropdown } from './components/resend-mail-dropdown'
import { UncancelBookingButton } from './components/uncancel-booking-button'

const bookingDocument = graphql(`
  query Booking($bookingId: ID!) {
    booking(id: $bookingId) {
      id
      number
      status
      bookingExport {
        id
        accountingRef
      }
      ...BookingInfo
      ...ResendMailDropdown
    }
  }
`)

export const Booking = () => {
  const params = useParams<'bookingId'>()
  const viewer = useViewer()
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [{ data, fetching, error }] = useQuery({
    query: bookingDocument,
    variables: { bookingId: params.bookingId! },
  })

  if (fetching) return <Loading />
  if (error) return <QueryError error={error} />
  if (!data) return null

  const booking = data.booking
  const isAdmin = viewer.hasRole('admin')

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-4xl font-bold leading-loose">
        Booking #{booking.number}
      </h1>

      {booking.bookingExport?.accountingRef && (
        <WarningAlert
          title="Eksportert til regnskap"
          description="Denne bookingen er fakturert via regnskapssystem. Prisendringer her vil ikke ha noen effekt."
          className="mb-2"
        />
      )}

      {isAdmin && (
        <div className="mb-4 flex flex-row justify-end gap-2">
          {booking.status !== 'canceled' && (
            <>
              {!booking.bookingExport && (
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => setShowExportDialog(true)}
                >
                  Fakturer
                </Button>
              )}

              <ResendMailDropdown booking={booking} />

              {booking.status === 'pending' && (
                <Button asChild variant="secondary" size="sm">
                  <Link to="./accept">Gi plass</Link>
                </Button>
              )}

              <Button asChild variant="secondary" size="sm">
                <Link to="./update-term">Endre periode</Link>
              </Button>

              <CancelBookingButton bookingId={booking.id} />
            </>
          )}

          {booking.status === 'canceled' && (
            <UncancelBookingButton bookingId={booking.id} />
          )}
        </div>
      )}

      <BookingInfo booking={booking} />

      {showExportDialog && (
        <CreateAccountingExportDialog
          bookingId={booking.id}
          onClose={() => setShowExportDialog(false)}
        />
      )}

      <Outlet />
    </div>
  )
}
