import { useQuery } from 'urql'
import { DeepPartial, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { graphql } from '@/__generated__/gql'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'

type Props = {
  defaultValues?: DeepPartial<FormValues>
  onSubmit: (input: FormValues) => void | Promise<void>
}

const formSchema = z.object({
  name: z.string({ errorMap: () => ({ message: 'Navn må fylles ut' }) }).min(2),
  email: z
    .string({ errorMap: () => ({ message: 'E-post må fylles ut' }) })
    .email(),
  phone: z
    .string({ errorMap: () => ({ message: 'Telefon må fylles ut' }) })
    .min(8)
    .nullable(),
  active: z.boolean(),
  userRoles: z.array(z.string()),
  userTerms: z.array(z.string()),
})

const userFormDocument = graphql(`
  query UserFormQuery {
    availableUserRoles
    activeTerms {
      id
      name
    }
  }
`)

export type FormValues = z.infer<typeof formSchema>

export const UserForm = ({ defaultValues, onSubmit }: Props) => {
  const [{ data }] = useQuery({ query: userFormDocument })
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: { userRoles: [], userTerms: [], ...defaultValues },
  })

  const hasEmployeeRole = form.watch('userRoles').includes('employee')
  const availableUserRoles = data?.availableUserRoles || []
  const activeTerms = data?.activeTerms || []

  return (
    <Form {...form}>
      <form
        id="user-form"
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <fieldset className="flex flex-col gap-5">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Fullt navn</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>E-post</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Telefon</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    value={field.value || ''}
                    onChange={(e) => field.onChange(e.target.value || null)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="active"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="active"
                      checked={field.value}
                      onCheckedChange={(v) => field.onChange(!!v)}
                    />
                    <Label htmlFor="active">Aktivert</Label>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </fieldset>

        <fieldset>
          <legend>Roller</legend>
          <FormField
            control={form.control}
            name="userRoles"
            render={({ field }) => (
              <>
                {availableUserRoles.map((role) => (
                  <div key={role} className="flex items-center space-x-2">
                    <Checkbox
                      id={`role-${role}`}
                      checked={field.value.includes(role)}
                      onCheckedChange={(v) =>
                        field.onChange(
                          v
                            ? [...field.value, role]
                            : field.value.filter((r) => r !== role)
                        )
                      }
                    />
                    <Label htmlFor={`role-${role}`}>{role}</Label>
                  </div>
                ))}
              </>
            )}
          />
        </fieldset>

        {hasEmployeeRole && (
          <fieldset>
            <legend>Perioder</legend>
            <FormField
              control={form.control}
              name="userTerms"
              render={({ field }) => (
                <>
                  {activeTerms.map((term) => (
                    <div key={term.id} className="flex items-center space-x-2">
                      <Checkbox
                        id={`term-${term.id}`}
                        checked={field.value.includes(term.id)}
                        onCheckedChange={(v) =>
                          field.onChange(
                            v
                              ? [...field.value, term.id]
                              : field.value.filter((r) => r !== term.id)
                          )
                        }
                      />
                      <Label htmlFor={`term-${term.id}`}>{term.name}</Label>
                    </div>
                  ))}
                </>
              )}
            />
          </fieldset>
        )}
      </form>
    </Form>
  )
}
