import { useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

type Props = {
  seasonId: string
  value: string | null
  onChange: (value: string | null) => void
}

const termsDocument = graphql(`
  query TermSelector($seasonId: ID!) {
    terms(seasonId: $seasonId) {
      id
      name
    }
  }
`)

export const TermSelector = ({ seasonId, value, onChange }: Props) => {
  const [{ data }] = useQuery({
    query: termsDocument,
    variables: {
      seasonId,
    },
    pause: !seasonId,
  })

  const terms = data?.terms || []

  return (
    <Select
      value={String(value)}
      onValueChange={(v) => onChange(v === 'null' ? null : v)}
    >
      <SelectTrigger className="w-[180px]">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Perioder</SelectLabel>
          <SelectItem value="null">Alle perioder</SelectItem>
          {terms.map((term) => (
            <SelectItem key={term.id} value={term.id}>
              {term.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
