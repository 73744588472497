export const phrases = {
  error: {
    title: 'Whoops, noe gikk galt!',
    description:
      'En uventet feil har oppstått. Vennligst last siden på nytt og prøv igjen.',
  },
  bookingStatuses: {
    pending: 'Venteliste',
    accepted: 'Fått plass',
    canceled: 'Kansellert',
  },
  bookingCancellationOptions: {
    refundPayment: 'Refunder forhåndsbetaling',
    cancelPayment: 'Kanseller forhåndsbetaling',
    creditInvoice: 'Krediter faktura',
  },
}
