import { useMutation } from 'urql'
import { graphql } from '@/__generated__/gql'
import { ResendMailDropdownFragment } from '@/__generated__/gql/graphql'
import { phrases } from '@/utils/phrases'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'

type Props = {
  booking: ResendMailDropdownFragment
}

export const resendMailDocument = graphql(`
  fragment ResendMailDropdown on Booking {
    id
    status
    reservation {
      id
      accepted
    }
    order {
      id
    }
  }
`)

const enqueueMailerDocument = graphql(`
  mutation EnqueueMailer($input: EnqueueMailerInput!) {
    enqueueMailer(input: $input) {
      success
    }
  }
`)

export const ResendMailDropdown = ({ booking }: Props) => {
  const [, enqueueMailer] = useMutation(enqueueMailerDocument)
  const { toast } = useToast()

  const handleSendMail = async ({
    mailer,
    data,
    name,
  }: { mailer: string; data: string; name: string }) => {
    const { error } = await enqueueMailer({
      input: { mailer, data },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'E-post sendt',
      description: `${name} er sendt til kontaktperson`,
      variant: 'default',
    })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="sm">
          Send e-post
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {booking.status !== 'canceled' && (
          <>
            <DropdownMenuItem
              onClick={() =>
                handleSendMail({
                  mailer: 'orderConfirmationMailer',
                  data: booking.order.id,
                  name: 'Ordrebekreftelse',
                })
              }
            >
              Ordrebekreftelse
            </DropdownMenuItem>
            {booking.reservation.accepted && (
              <DropdownMenuItem
                onClick={() =>
                  handleSendMail({
                    mailer: 'bookingConfirmationMailer',
                    data: booking.id,
                    name: 'Bookingbekreftelse',
                  })
                }
              >
                Bookingbekreftelse
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onClick={() =>
                handleSendMail({
                  mailer: 'creditLimitNoticeMailer',
                  data: booking.id,
                  name: 'Kioskpenger-info',
                })
              }
            >
              Kioskpenger-info
            </DropdownMenuItem>
          </>
        )}
        {booking.status === 'canceled' && (
          <DropdownMenuItem
            onClick={() =>
              handleSendMail({
                mailer: 'bookingCanceledMailer',
                data: booking.id,
                name: 'Avbestillingsbekreftelse',
              })
            }
          >
            Avbestillingsbekreftelse
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
