import { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'urql'
import { createUrqlClient } from '@/lib/urql-client'
import useVersionCheck from '@/hooks/use-version-check'
import { RootRoutes } from '@/routes'
import { Toaster } from '@/components/ui/toaster'
import { OutdatedVersionNotification } from '@/components/outdated-version-notification'
import { ViewerContextProvider } from '@/components/viewer-context-provider'
import '@/index.css'

export const App = () => {
  const { customFetch, hasNewVersion } = useVersionCheck()
  const [urqlClient] = useState(() => createUrqlClient({ fetch: customFetch }))

  return (
    <Provider value={urqlClient}>
      <ViewerContextProvider>
        <Toaster />
        <OutdatedVersionNotification open={hasNewVersion} />
        <BrowserRouter>
          <RootRoutes />
        </BrowserRouter>
      </ViewerContextProvider>
    </Provider>
  )
}
