import { Route, Routes, Navigate } from 'react-router-dom'
import { Booking } from '@/routes/bookings/booking'
import { Bookings } from '@/routes/bookings/bookings'
import { Layout } from '@/components/layout'
import { AcceptBooking } from './accept-booking'
import { UpdateTerm } from './update-term'
import { UpdateCustomerDialog } from './update-customer-dialog'
import { UpdateBooking } from './update-booking'
import { CreateContactPersonDialog } from './create-contact-person-dialog'

export const BookingsRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/:bookingId" element={<Booking />}>
          <Route path="accept" element={<AcceptBooking />} />
          <Route path="update-booking" element={<UpdateBooking />} />
          <Route path="update-term" element={<UpdateTerm />} />
          <Route path="update-customer" element={<UpdateCustomerDialog />} />
          <Route
            path="create-contact-person/:orderId"
            element={<CreateContactPersonDialog />}
          />
        </Route>
        <Route path="/" element={<Bookings />} />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </Layout>
  )
}
