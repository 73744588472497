import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { refreshAuth } from '@/lib/refresh-auth'
import { useOnMount } from '@/hooks/use-on-mount'
import { BookingsRoutes } from '@/routes/bookings'
import { Login } from '@/routes/login'
import { TermsRoutes } from '@/routes/terms'
import { SeasonsRoutes } from '@/routes/seasons'
import { AccountingExportsRoutes } from '@/routes/accounting-export'
import { UsersRoutes } from '@/routes/users'
import { useViewer } from '@/components/viewer-context-provider'

function Authenticated({ children }: { children: React.ReactNode }) {
  const viewer = useViewer()
  const navigate = useNavigate()

  useOnMount(async () => {
    if (!viewer.user) {
      const didRefreshAuth = await refreshAuth()
      if (didRefreshAuth) {
        window.location.reload()
      } else {
        navigate('/login', { replace: true })
      }
    }
  })

  if (viewer.user) {
    return children
  }

  return null
}

export const RootRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="/bookings/*"
        element={
          <Authenticated>
            <BookingsRoutes />
          </Authenticated>
        }
      />
      <Route
        path="/seasons/*"
        element={
          <Authenticated>
            <SeasonsRoutes />
          </Authenticated>
        }
      />
      <Route
        path="/terms/*"
        element={
          <Authenticated>
            <TermsRoutes />
          </Authenticated>
        }
      />
      <Route
        path="/users/*"
        element={
          <Authenticated>
            <UsersRoutes />
          </Authenticated>
        }
      />
      <Route
        path="/accounting-exports/*"
        element={
          <Authenticated>
            <AccountingExportsRoutes />
          </Authenticated>
        }
      />
      <Route path="/" element={<Navigate to="/bookings" />} />
      <Route path="*" element={<div>404</div>} />
    </Routes>
  )
}
