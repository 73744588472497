import { useCallback, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useMutation } from 'urql'
import { graphql } from '@/__generated__/gql'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useViewer } from '@/components/viewer-context-provider'
import { LoginForm, FormValues } from './components/login-form'
import { Button } from '@/components/ui/button'

const requestOtpDocument = graphql(`
  mutation RequestOtp($input: OtpInput!) {
    requestOtp(input: $input) {
      viewer {
        id
      }
    }
  }
`)

const loginDocument = graphql(`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      viewer {
        id
        user {
          id
        }
      }
    }
  }
`)

export const Login = () => {
  const viewer = useViewer()
  const [email, setEmail] = useState('')
  const [otpResult, requestOtp] = useMutation(requestOtpDocument)
  const [loginResult, login] = useMutation(loginDocument)

  const submitError = otpResult.error || loginResult.error

  const handleSubmit = useCallback(
    async (input: FormValues) => {
      const { email, otp } = input
      if (email && otp) {
        const { error } = await login({
          input: { email, otp },
        })
        if (!error) viewer.reload()
      } else if (email) {
        await requestOtp({
          input: { email },
        })
        setEmail(email)
      }
    },
    [requestOtp, login, viewer, setEmail]
  )

  if (viewer.user) {
    return <Navigate to="/bookings" replace />
  }

  return (
    <div className=" w-full p-8 flex justify-center">
      <Card className="w-1/2">
        <CardHeader>
          <CardTitle>Logg på</CardTitle>
        </CardHeader>
        <CardContent>
          {submitError && (
            <p className="mt-3 text-red-500">
              Kunne ikke logge inn. Dobbeltsjekk e-postadresse og kode.
            </p>
          )}
          <LoginForm key={email} email={email} onSubmit={handleSubmit} />

          <div className="flex flex-col mt-4 gap-2">
            <Button form="login-form" type="submit">
              {email ? 'Logg inn' : 'Fortsett'}
            </Button>

            {email && (
              <Button variant="secondary" onClick={() => setEmail('')}>
                Avbryt
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default Login
