import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { CancelOption } from '@/__generated__/gql/graphql'
import { phrases } from '@/utils/phrases'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { QueryError } from '@/components/query-error'

export const cancelBookingDialogDocument = graphql(`
  query CancelBookingDialog($bookingId: ID!) {
    booking(id: $bookingId) {
      id
      cancellationOptions
    }
  }
`)

export const cancelBookingDocument = graphql(`
  mutation CancelBooking($input: CancelBookingInput!) {
    cancelBooking(input: $input) {
      booking {
        id
        status
      }
    }
  }
`)

export const CancelBookingButton = ({ bookingId }: { bookingId: string }) => {
  const navigate = useNavigate()
  const { toast } = useToast()
  const [options, setOptions] = useState<CancelOption[]>([])
  const [{ data, error }] = useQuery({
    query: cancelBookingDialogDocument,
    variables: { bookingId },
  })
  const [_, cancelBooking] = useMutation(cancelBookingDocument)

  const cancellationOptions = data?.booking?.cancellationOptions || []

  const onCancelBooking = async () => {
    const { error } = await cancelBooking({
      input: { bookingId, options },
    })

    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Kansellert!',
      description: 'Bookingen er kansellert.',
      variant: 'default',
    })
    navigate('/bookings')
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          Kanseller booking
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Kanseller booking</DialogTitle>
          <DialogDescription>
            Er du sikker på at du vil kansellere denne bookingen?
          </DialogDescription>
        </DialogHeader>
        {data && (
          <>
            {cancellationOptions.map((option) => (
              <div key={option} className="flex items-center space-x-2">
                <Checkbox
                  id={option}
                  checked={options.includes(option)}
                  onCheckedChange={(value) => {
                    setOptions((prev) =>
                      value
                        ? [...prev, option]
                        : prev.filter((o) => o !== option)
                    )
                  }}
                />
                <Label htmlFor={option}>
                  {phrases.bookingCancellationOptions[option]}
                </Label>
              </div>
            ))}

            <DialogFooter>
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Avbryt
                </Button>
              </DialogClose>
              <Button type="submit" onClick={onCancelBooking}>
                Kanseller
              </Button>
            </DialogFooter>
          </>
        )}
        {error && <QueryError error={error} />}
      </DialogContent>
    </Dialog>
  )
}
