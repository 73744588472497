import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { toast } from '@/components/ui/use-toast'
import { phrases } from '@/utils/phrases'

const changeTermDialogDocument = graphql(`
  query changeTermDialog($bookingId: ID!) {
    booking(id: $bookingId) {
      id
      term {
        id
        season {
          id
          terms{
            id
            name
            acceptedParticipantCount
            maxParticipants
          }
        }
      }
    }
  }
`)

const changeTermDocument = graphql(`
  mutation UpdateTermBooking($input: ChangeTermInput!) {
    changeTerm(input: $input) {
      booking {
        id
        status
        reservation {
          id
          accepted
        }
      }
    }
  }
`)

export const UpdateTerm = () => {
  const params = useParams<'bookingId'>()
  const navigate = useNavigate()
  const [selectedTerm, setSelectedTerm] = useState<string>('')
  const [{ data, fetching, error }] = useQuery({
    query: changeTermDialogDocument,
    variables: { bookingId: params.bookingId! },
  })
  const [{ fetching: submitting }, changeTerm] = useMutation(changeTermDocument)

  const terms =
    data?.booking.term.season.terms.filter(
      (term) => term.id !== data.booking.term.id
    ) || []

  useEffect(() => {
    if (terms.length > 0 && !selectedTerm) {
      setSelectedTerm(terms[0].id)
    }
  }, [terms])

  const onClose = () => navigate('..')

  const onConfirm = async () => {
    const { error } = await changeTerm({
      input: { bookingId: params.bookingId!, termId: selectedTerm },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Perioden endret!',
      description: 'Bookingen er endret til den nye perioden.',
      variant: 'default',
    })
    onClose()
  }
  const selectedTermObject = terms.find((term) => term.id === selectedTerm)
  const maxParticipants = selectedTermObject?.maxParticipants || 0
  const acceptedParticipantCount =
    selectedTermObject?.acceptedParticipantCount || 0

  const difference = maxParticipants - acceptedParticipantCount

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        {fetching && <Loading />}
        {error && <QueryError error={error} />}
        {data && (
          <>
            <DialogHeader>
              <DialogTitle>Vil du endre perioden?</DialogTitle>
              <DialogDescription>
                Som admin kan du endre perioden til en booking.
              </DialogDescription>
            </DialogHeader>
            {terms.length > 0 && (
              <Select
                defaultValue={terms[0].id}
                onValueChange={(newValue) => setSelectedTerm(newValue)}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Perioder</SelectLabel>
                    {terms.map((term) => (
                      <SelectItem key={term.id} value={term.id}>
                        {term.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
            <p>
              Denne perioden har <strong>{selectedTerm && difference}</strong>{' '}
              ledige plasser.
            </p>
            <DialogFooter>
              <Button type="button" variant="secondary" onClick={onClose}>
                Avbryt
              </Button>
              <Button type="button" onClick={onConfirm} disabled={submitting}>
                Bekreft endring
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
