import { useEffect, useState } from 'react'
import { Input } from './ui/input'

type Props = {
  delay?: number
  value?: string
  onChange: (value: string) => void
  placeholder?: string
  className?: string
}

export const DebouncedInput = ({
  delay = 300,
  value = '',
  onChange,
  ...rest
}: Props) => {
  const [searchQuery, setSearchQuery] = useState(value)

  useEffect(() => {
    if (searchQuery === value) return
    const handler = setTimeout(() => {
      onChange(searchQuery)
    }, delay)
    return () => clearTimeout(handler)
  }, [searchQuery])

  return (
    <Input
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      {...rest}
    />
  )
}
