import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { toast } from '@/components/ui/use-toast'
import { CustomerForm, FormValues } from './components/customer-form'

export const customerFragment = graphql(`
  fragment UpdateCustomerData on Customer {
    id
    firstName
    lastName
    email
    phone
    address {
      streetAddress
      postalCode
      postalArea
    }
    postAddress {
      streetAddress
      postalCode
      postalArea
    }
  }
`)

export const updateCustomerDataDocument = graphql(`
  query UpdateCustomerData($bookingId: ID!) {
    booking(id: $bookingId) {
      id
      order {
        id
        customer {
          id
          ...UpdateCustomerData
        }
      }
    }
  }
`)

const updateCustomerDocument = graphql(`
  mutation UpdateCustomer($orderId: ID!, $input: CustomerInput!) {
    updateCustomer(orderId: $orderId, input: $input) {
      customer {
        id
        ...UpdateCustomerData
      }
    }
  }
`)

export const UpdateCustomerDialog = () => {
  const params = useParams<'bookingId'>()
  const navigate = useNavigate()
  const [{ data, fetching, error }] = useQuery({
    query: updateCustomerDataDocument,
    variables: { bookingId: params.bookingId! },
  })
  const [{ fetching: submitting }, updateCustomer] = useMutation(
    updateCustomerDocument
  )

  const onClose = () => navigate('..')
  const onSubmit = async (input: FormValues) => {
    if (!data) return
    const { error } = await updateCustomer({
      orderId: data.booking.order.id,
      input: {
        ...input,
        postAddress: input.postAddress.streetAddress ? input.postAddress : null,
      },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Foresatt er oppdatert',
      variant: 'default',
    })
    onClose()
  }

  const customer = data?.booking.order.customer

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        {fetching && <Loading />}
        {error && <QueryError error={error} />}
        {data && (
          <>
            <DialogHeader>
              <DialogTitle>Rediger foresatt</DialogTitle>
            </DialogHeader>
            <CustomerForm
              defaultValues={{
                ...customer,
                postAddress: {
                  streetAddress: customer?.postAddress?.streetAddress ?? '',
                  postalCode: customer?.postAddress?.postalCode ?? '',
                  postalArea: customer?.postAddress?.postalArea ?? '',
                },
              }}
              onSubmit={onSubmit}
            />
            <DialogFooter>
              <Button type="button" variant="secondary" onClick={onClose}>
                Avbryt
              </Button>
              <Button form="customer-form" type="submit" disabled={submitting}>
                Bekreft endring
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
