import { Link } from 'react-router-dom'
import { Pencil2Icon } from '@radix-ui/react-icons'
import { CustomerTableRowFragment } from '@/__generated__/gql/graphql'
import { graphql } from '@/__generated__/gql'
import { TableLayout } from '@/components/table-layout'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { useViewer } from '@/components/viewer-context-provider'

export const customerTableRowDocument = graphql(`
  fragment CustomerTableRow on Customer {
    id
    firstName
    lastName
    email
    phone
    address {
      streetAddress
      postalCode
      postalArea
    }
    postAddress {
      streetAddress
      postalCode
      postalArea
    }
  }
`)

type CustomerTableProps = {
  customer: CustomerTableRowFragment
}

export const CustomerTable = ({ customer }: CustomerTableProps) => {
  const viewer = useViewer()

  return (
    <TableLayout>
      <TableHeader>
        <TableRow>
          <TableHead>Navn</TableHead>
          <TableHead>E-post</TableHead>
          <TableHead>Telefonnummer</TableHead>
          <TableHead>Adresse</TableHead>
          <TableHead>Postadresse</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>
            {customer.firstName} {customer.lastName}
          </TableCell>
          <TableCell>{customer.email}</TableCell>
          <TableCell>{customer.phone}</TableCell>
          <TableCell>
            {customer.address.streetAddress}, {customer.address.postalCode}{' '}
            {customer.address.postalArea}
          </TableCell>
          <TableCell>
            {customer.postAddress?.streetAddress && (
              <>
                {customer.postAddress.streetAddress},{' '}
                {customer.postAddress.postalCode}{' '}
                {customer.postAddress.postalArea}
              </>
            )}
          </TableCell>
          <TableCell className="py-1">
            {' '}
            {viewer.hasRole('admin') && (
              <Button asChild variant="link">
                <Link to="./update-customer" className="pt-0">
                  <Pencil2Icon className="w-4 h-4" />
                </Link>
              </Button>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </TableLayout>
  )
}
