import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { useToast } from '@/components/ui/use-toast'

const confirmBookingDialogDocument = graphql(`
  query ConfirmBookingDialog($bookingId: ID!) {
    booking(id: $bookingId) {
      id
      reservation {
        id
        accepted
      }
      term {
        id
        maxParticipants
        acceptedParticipantCount
      }
    }
  }
`)

const acceptBookingDocument = graphql(`
  mutation AcceptBooking($input: AcceptBookingInput!) {
    acceptBooking(input: $input) {
      booking {
        id
        status
        reservation {
          id
          accepted
        }
      }
    }
  }
`)

export const AcceptBooking = () => {
  const params = useParams<'bookingId'>()
  const navigate = useNavigate()
  const { toast } = useToast()
  const [{ data, fetching, error }] = useQuery({
    query: confirmBookingDialogDocument,
    variables: { bookingId: params.bookingId! },
  })
  const [{ fetching: submitting }, acceptBooking] = useMutation(
    acceptBookingDocument
  )

  const onClose = () => navigate('..')

  const onConfirm = async () => {
    const { error } = await acceptBooking({
      input: { bookingId: params.bookingId! },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Booking bekreftet!',
      description: 'Bookingen ble flyttet fra venteliste.',
      variant: 'default',
    })
    onClose()
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        {fetching && <Loading />}
        {error && <QueryError error={error} />}
        {data && (
          <>
            {data.booking.reservation.accepted && <Navigate to=".." />}

            <DialogHeader>
              <DialogTitle>Vil du flytte bookingen fra venteliste?</DialogTitle>
              <DialogDescription>
                Som admin kan du gi plass til en booking fra venteliste selv om
                perioden ikke har flere ledige plasser.
              </DialogDescription>
            </DialogHeader>
            <p>
              Denne perioden har allerede{' '}
              <strong>{data.booking.term.acceptedParticipantCount}</strong>/
              <strong>{data.booking.term.maxParticipants}</strong> påmeldte.
            </p>
            <DialogFooter>
              <Button type="button" variant="secondary" onClick={onClose}>
                Avbryt
              </Button>
              <Button type="button" onClick={onConfirm} disabled={submitting}>
                Bekreft plass
              </Button>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
