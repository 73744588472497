import { ReactNode } from 'react'

type FieldWrapperProps = {
  label: string
  children?: ReactNode
  className?: string
}

export const FieldWrapper = ({
  label,
  children,
  className,
}: FieldWrapperProps) => {
  return (
    <div className={`w-1/2 md:w-1/3 lg:w-1/4 p-2 ${className}`}>
      <div className="font-semibold">{label}</div>
      <div>{children}</div>
    </div>
  )
}
