import { Outlet } from 'react-router-dom'
import { useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { formatDate } from '@/utils/format-date'
import { useQueryParams } from '@/hooks/use-query-params'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { WarningAlert } from '@/components/warning-alert'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { TableLayout } from '@/components/table-layout'
import { Pagination } from '@/components/pagination'

const accountingExportsDocument = graphql(`
  query AccountingExportsTable($input: ConnectionInputArgs!) {
    accountingExports(input: $input) {
      totalCount
      results {
        id
        description
        bookingsCount
        bookingsCompletedCount
        createdAt
      }
    }
  }
`)

export const AccountingExports = () => {
  const [queryParams] = useQueryParams()
  const limit = 50
  const page = Number(queryParams.page ?? 1)

  const [{ data, fetching, error }, refetch] = useQuery({
    query: accountingExportsDocument,
    variables: {
      input: { limit, page },
    },
  })

  const accountingExports =
    data && !fetching ? data.accountingExports.results : []

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-bold leading-loose">Fakturering</h1>
      </div>

      <TableLayout>
        <TableHeader>
          <TableRow>
            <TableHead>Beskrivelse</TableHead>
            <TableHead>Opprettet</TableHead>
            <TableHead>Eksporterte bookinger</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {accountingExports.map((accountingExport) => (
            <TableRow key={accountingExport.id}>
              <TableCell>{accountingExport.description}</TableCell>
              <TableCell>
                {formatDate(accountingExport.createdAt, { style: 'time' })}
              </TableCell>
              <TableCell>
                {accountingExport.bookingsCompletedCount}/
                {accountingExport.bookingsCount}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableLayout>

      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {accountingExports.length === 0 && !fetching && !error && (
        <WarningAlert
          title="Ingen resultater"
          description="Ingen faktureringer ble funnet."
        />
      )}
      {data && data.accountingExports.totalCount > limit && (
        <Pagination
          className="mt-4"
          total={data.accountingExports.totalCount}
          limit={limit}
        />
      )}

      <Outlet context={{ refetch }} />
    </>
  )
}
