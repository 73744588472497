import { format, toZonedTime } from 'date-fns-tz'
import { nb } from 'date-fns/locale'
import { config } from '@/config'

const dateFormats = {
  time: 'dd.MM.yyyy HH:mm',
  short: 'dd.MM.yyyy',
  year: 'yyyy',
} as const

export const formatDate = (
  date: Date | string,
  options: { style?: keyof typeof dateFormats } = {}
) => {
  const d = toZonedTime(new Date(date), config.tz)
  return format(d, dateFormats[options.style || 'short'], {
    locale: nb,
  })
}
