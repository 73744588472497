import { useMutation } from 'urql'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { FormValues, SeasonForm } from './components/season-form'

const createSeasonDocument = graphql(`
  mutation CreateSeason($input: SeasonInput!) {
    createSeason(input: $input) {
      season {
        id
        name
      }
    }
  }
`)

export const NewSeason = () => {
  const { refetch } = useOutletContext<{ refetch: () => void }>()
  const navigate = useNavigate()
  const { toast } = useToast()
  const [_, createSeason] = useMutation(createSeasonDocument)

  const onClose = () => navigate('..')
  const onSubmit = async (values: FormValues) => {
    const { error } = await createSeason({
      input: {
        ...values,
        registrationOpensAt: new Date(values.registrationOpensAt).toISOString(),
      },
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Sesong opprettet',
      description: 'Ny sesong ble opprettet',
      variant: 'default',
    })
    refetch()
    onClose()
  }

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Ny sesong</DialogTitle>
        </DialogHeader>

        <SeasonForm
          onSubmit={onSubmit}
          defaultValues={{
            name: '',
            registrationOpensAt: '',
          }}
        />

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Avbryt
          </Button>
          <Button form="season-form" type="submit">
            Opprett
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
