import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from '@/components/layout'
import { AccountingExports } from './accounting-exports'

export const AccountingExportsRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<AccountingExports />} />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </Layout>
  )
}
