import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from '@/components/layout'
import { Season } from './season'
import { Seasons } from './seasons'
import { NewSeason } from './new-season'
import { EditSeason } from './edit-season'

export const SeasonsRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Seasons />}>
          <Route path="new" element={<NewSeason />} />
          <Route path=":seasonId" element={<Season />} />
          <Route path=":seasonId/edit" element={<EditSeason />} />
        </Route>
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </Layout>
  )
}
