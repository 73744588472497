import { BookingsTableRowFragment } from '@/__generated__/gql/graphql'
import { TableLayout } from '@/components/table-layout'
import {
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { OrderDirection } from '@/components/order-direction'
import { BookingTableRow } from './booking-table-row'

type BookingsTableProps = {
  bookings: BookingsTableRowFragment[]
  order?: string
}

export const BookingsTable = ({ bookings, order }: BookingsTableProps) => {
  const columns = [
    { label: '#' },
    { label: 'Navn', field: 'booking.firstName' },
    { label: 'Etternavn', field: 'booking.lastName' },
    { label: 'Periode', field: 'term.name' },
    { label: 'Status', field: 'reservation.accepted' },
    { label: 'Årskull', field: 'booking.dob' },
    { label: 'Tidligere år', field: 'booking.pastVisits' },
    { label: 'Genser', field: 'booking.sweaterSize' },
    { label: 'Bookingdato', field: 'booking.createdAt' },
    { label: 'Rabatt', field: 'booking.discount' },
    { label: 'Fakturert', field: 'exported' },
  ]

  return (
    <TableLayout>
      <TableHeader>
        <TableRow>
          {columns.map(({ label, field }) => (
            <TableHead key={label}>
              {order && field && (
                <OrderDirection label={label} field={field} value={order} />
              )}
              {(!order || !field) && label}
            </TableHead>
          ))}
          <TableHead />
        </TableRow>
      </TableHeader>
      <TableBody>
        {bookings.map((booking) => (
          <BookingTableRow key={booking.id} booking={booking} />
        ))}
      </TableBody>
    </TableLayout>
  )
}
