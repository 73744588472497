import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { cn } from '@/utils/cn'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'

type WarningAlertProps = {
  title: string
  description: string
  className?: string
}
export const WarningAlert = ({
  title,
  description,
  className,
}: WarningAlertProps) => {
  const classes = cn('bg-amber-100 text-orange-900 border-none', className)
  return (
    <Alert className={classes}>
      <ExclamationTriangleIcon className="h-4 w-4 stroke-orange-500 stroke-[0.3px]" />
      <AlertTitle className="flex">{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  )
}
