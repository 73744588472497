import { BookingsInfo } from './components/bookings-info'

export const Bookings = () => {
  return (
    <>
      <h1 className="text-4xl font-bold leading-loose">Bookinger</h1>
      <BookingsInfo />
    </>
  )
}
