import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from '@/components/layout'
import { Users } from './users'
import { NewUser } from './new-user'
import { EditUser } from './edit-user'

export const UsersRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Users />}>
          <Route path="new" element={<NewUser />} />
          <Route path=":userId/edit" element={<EditUser />} />
        </Route>
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </Layout>
  )
}
