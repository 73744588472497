import { useQuery } from 'urql'
import { DownloadIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { config } from '@/config'
import { useQueryParams } from '@/hooks/use-query-params'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { Button } from '@/components/ui/button'
import { Pagination } from '@/components/pagination'
import { WarningAlert } from '@/components/warning-alert'
import { SeasonSelector } from '@/components/season-selector'
import { TermSelector } from '@/components/term-selector'
import { DebouncedInput } from '@/components/debounced-input'
import { BookingsTable } from './bookings-table'

const bookingsDocument = graphql(`
  query Bookings($input: BookingsConnectionInputArgs!) {
    bookings(input: $input) {
      totalCount
      results {
        id
        ...BookingsTableRow
      }
    }
  }
`)

export const BookingsInfo = () => {
  const [queryParams, setQueryParams] = useQueryParams()
  const limit = 50
  const page = Number(queryParams.page ?? 1)
  const seasonId = queryParams.seasonId ?? ''
  const termId = queryParams.termId ?? null
  const query = queryParams.query
  const order = queryParams.order ?? 'booking.createdAt:DESC'

  const [{ data, fetching, error }] = useQuery({
    query: bookingsDocument,
    variables: {
      input: { limit, page, seasonId, termId, query, order },
    },
    pause: !seasonId,
  })

  const bookings = data && !fetching ? data.bookings.results : []

  const downloadXlsx = () => {
    window.location.href = `${
      config.apiUrl
    }/export/bookings.xlsx?seasonId=${seasonId}&termId=${termId ?? ''}`
  }

  return (
    <>
      <div className="w-full mb-4 flex justify-between">
        <div className="flex flex-wrap gap-1">
          <SeasonSelector
            selectedSeason={seasonId}
            setSelectedSeason={(id) =>
              setQueryParams((prev) => ({ ...prev, seasonId: id }))
            }
          />
          <TermSelector
            seasonId={seasonId}
            value={termId}
            onChange={(id) =>
              setQueryParams((prev) => ({ ...prev, termId: id ?? undefined }))
            }
          />
          <DebouncedInput
            value={queryParams.query}
            onChange={(query) => setQueryParams((prev) => ({ ...prev, query }))}
            placeholder="Søk etter ID, navn, epost"
            className="w-[210px]"
          />
        </div>

        <Button
          variant="outline"
          onClick={downloadXlsx}
          disabled={bookings.length < 1}
          className="min-w-[160px]"
        >
          <DownloadIcon className="mr-2 h-4 w-4" />
          Last ned (.xlsx)
        </Button>
      </div>

      <BookingsTable order={order} bookings={bookings} />

      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {bookings.length === 0 && !fetching && !error && (
        <WarningAlert
          title="Ingen resultater"
          description="Ingen bookinger ble funnet."
        />
      )}
      {data && data.bookings.totalCount > limit && (
        <Pagination
          className="mt-4"
          total={data.bookings.totalCount}
          limit={limit}
        />
      )}
    </>
  )
}
