import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

type Props = {
  onSubmit: (input: FormValues) => Promise<void>
  email?: string
}

const formSchema = z.object({
  email: z.string().email('E-post må fylles ut'),
  otp: z.string().min(2, 'Engangspassord må fylles ut').nullable(),
})

export type FormValues = z.infer<typeof formSchema>

export function LoginForm({ onSubmit, email }: Props) {
  const form = useForm<FormValues>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
    defaultValues: { email, otp: null },
  })

  return (
    <Form {...form}>
      <form
        id="login-form"
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>E-postadresse</FormLabel>
              <FormControl>
                <Input disabled={!!email} autoFocus={!email} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {!!email && (
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Engangspassord</FormLabel>
                <FormControl>
                  <Input
                    autoFocus={!!email}
                    {...field}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </form>
    </Form>
  )
}
