const apiUrl = import.meta.env.VITE_API_URL

export const config = {
  tz: 'Europe/Oslo',
  targetLocale: 'nb-NO',
  apiUrl,
  graphqlUrl: `${apiUrl}/graphql`,
  authRefreshUrl: `${apiUrl}/auth/refresh`,
  authSessionUrl: `${apiUrl}/auth/session`,
  siblingDiscount: 5,
}
