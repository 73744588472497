import { NavLink } from 'react-router-dom'

type StylesNavLinkProps = {
  to: string
  children: React.ReactNode
}

export const StyledNavLink = ({ to, children }: StylesNavLinkProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive ? 'px-3 text-white underline' : 'px-3 text-white'
      }
    >
      {children}
    </NavLink>
  )
}
