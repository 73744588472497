import { DeepPartial, useForm } from 'react-hook-form'
import { Gender, SweaterSize } from '@/__generated__/gql/graphql'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Textarea } from '@/components/ui/textarea'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  preventTouchStart,
} from '@/components/ui/select'

type Props = {
  defaultValues?: DeepPartial<FormValues>
  onSubmit: (input: FormValues) => void | Promise<void>
}

const formSchema = z.object({
  firstName: z.string().min(2, 'Fornavn må fylles ut'),
  lastName: z.string().min(2, 'Etternavn må fylles ut'),
  dob: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, 'Fødselsdato må fylles ut'),
  gender: z.nativeEnum(Gender, {
    errorMap: () => ({ message: 'Kjønn må fylles ut' }),
  }),
  pastVisits: z.number(),
  friends: z.string().nullable(),
  notes: z.string().nullable(),
  sweaterSize: z.nativeEnum(SweaterSize).nullable(),
  creditLimit: z.number().nullable(),
  price: z
    .number({ errorMap: () => ({ message: 'Pris må fylles ut' }) })
    .min(0),
  discount: z
    .number({ errorMap: () => ({ message: 'Rabatt må fylles ut' }) })
    .min(0)
    .max(100),
})

export type FormValues = z.infer<typeof formSchema>

export const BookingForm = ({ defaultValues, onSubmit }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })
  const { handleSubmit } = form

  return (
    <Form {...form}>
      <form
        id="form-booking"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <fieldset className="flex flex-col gap-5">
          <FormField
            control={form.control}
            name={'firstName'}
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Fornavn</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'lastName'}
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Etternavn</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'dob'}
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Fødselsdato</FormLabel>
                <FormControl>
                  <Input type="date" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'gender'}
            render={({ field }) => (
              <FormItem>
                <Label required>Kjønn</Label>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex flex-row gap-x-5"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="male" />
                      </FormControl>
                      <FormLabel className="font-normal text-base">
                        Gutt
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="female" />
                      </FormControl>
                      <FormLabel className="font-normal text-base">
                        Jente
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="other" />
                      </FormControl>
                      <FormLabel className="font-normal text-base">
                        Annet
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'pastVisits'}
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Deltatt tidligere?</FormLabel>
                <Select
                  onValueChange={(v) => field.onChange(Number(v))}
                  defaultValue={String(field.value)}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent ref={preventTouchStart}>
                    {Array.from({ length: 11 }, (_, i) => (
                      <SelectItem key={String(i)} value={String(i)}>
                        {i === 0 ? 'Nei' : `Ja, ${i} år`}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'friends'}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Ønsker å bo med</FormLabel>
                <FormControl>
                  <Input {...field} value={field.value || ''} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'notes'}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Særlige opplysninger</FormLabel>
                <FormControl>
                  <Textarea {...field} value={field.value || ''} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'sweaterSize'}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Oksnøen-genser (800kr)</FormLabel>
                <Select
                  onValueChange={(v) => field.onChange(v === '0' ? null : v)}
                  defaultValue={field.value ?? '0'}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent ref={preventTouchStart}>
                    <SelectItem value="0">Nei takk</SelectItem>
                    <SelectItem value="xs">XS</SelectItem>
                    <SelectItem value="s">S</SelectItem>
                    <SelectItem value="m">M</SelectItem>
                    <SelectItem value="l">L</SelectItem>
                    <SelectItem value="xl">XL</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="creditLimit"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Kioskpenger</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min={0}
                    value={field.value ?? ''}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value ? Number(e.target.value) : null
                      )
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="price"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Pris</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min={0}
                    value={field.value ?? ''}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value ? Number(e.target.value) : null
                      )
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="discount"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Rabatt (%)</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="number"
                    min={0}
                    max={100}
                    value={field.value ?? ''}
                    onChange={(e) =>
                      field.onChange(
                        e.target.value ? Number(e.target.value) : null
                      )
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </fieldset>
      </form>
    </Form>
  )
}
