import { Link } from 'react-router-dom'
import { ContactPersonTableRowFragment } from '@/__generated__/gql/graphql'
import { graphql } from '@/__generated__/gql'
import { PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { TableLayout } from '@/components/table-layout'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { useViewer } from '@/components/viewer-context-provider'
import { DeleteContactPersonButton } from './delete-contact-person-button'

export const contactPersonTableRowDocument = graphql(`
  fragment ContactPersonTableRow on ContactPerson {
    id
    firstName
    lastName
    email
    phone
  }
`)

type ContactPersonTableProps = {
  contactPersons: ContactPersonTableRowFragment[]
  orderId: string
}

export const ContactPersonTable = ({
  contactPersons,
  orderId,
}: ContactPersonTableProps) => {
  const viewer = useViewer()

  return (
    <div className="mt-8">
      <div className="flex items-center  mb-2 ">
        <h2 className="font-bold text-xl mr-4">Kontaktperson</h2>
        {viewer.hasRole('admin') && (
          <Button asChild size="sm">
            <Link to={`./create-contact-person/${orderId}`}>
              <PlusIcon />
            </Link>
          </Button>
        )}
      </div>
      {contactPersons.length > 0 && (
        <TableLayout>
          <TableHeader>
            <TableRow>
              <TableHead>Navn</TableHead>
              <TableHead>E-post</TableHead>
              <TableHead>Telefonnummer</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {contactPersons.map((contactPerson) => (
              <TableRow key={contactPerson.id}>
                <TableCell>
                  {contactPerson.firstName} {contactPerson.lastName}
                </TableCell>
                <TableCell>{contactPerson.email}</TableCell>
                <TableCell>{contactPerson.phone}</TableCell>
                <TableCell>
                  {viewer.hasRole('admin') && (
                    <DeleteContactPersonButton
                      contactPersonId={contactPerson.id}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableLayout>
      )}
    </div>
  )
}
