import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { formatDate } from '@/utils/format-date'
import { phrases } from '@/utils/phrases'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'

const seasonDocument = graphql(`
  query Season($seasonId: ID!) {
    season(id: $seasonId) {
      id
      name
      registrationOpensAt
      active
      canSetSweaterSize
      canSetCreditLimit
    }
  }
`)

const activateSeasonDocument = graphql(`
  mutation ActivateSeason($id: ID!) {
    activateSeason(id: $id) {
      season {
        id
        active
      }
    }
  }

`)

export const Season = () => {
  const { refetch } = useOutletContext<{ refetch: () => void }>()
  const navigate = useNavigate()
  const params = useParams<'seasonId'>()
  const { toast } = useToast()
  const [{ data, fetching, error }] = useQuery({
    query: seasonDocument,
    variables: { seasonId: params.seasonId! },
  })
  const [{ fetching: submitting }, activateSeason] = useMutation(
    activateSeasonDocument
  )

  const onClose = () => navigate('..')
  const onActivate = async () => {
    const { error } = await activateSeason({
      id: params.seasonId!,
    })
    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Sesong aktivert',
      description: 'Sesongen ble aktivert',
      variant: 'default',
    })
    refetch()
    onClose()
  }

  if (fetching) return <Loading />
  if (error) return <QueryError error={error} />
  if (!data) return null

  const season = data.season

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Sesong {season.name}</DialogTitle>
          <DialogDescription />
        </DialogHeader>

        <div className="px-2">
          <div className="font-semibold">Påmeldingsstart</div>
          <div>{formatDate(season.registrationOpensAt, { style: 'time' })}</div>
        </div>
        <div className="px-2">
          <div className="font-semibold">Aktiv</div>
          <div>{season.active ? 'Ja' : 'Nei'}</div>
        </div>
        <div className="px-2">
          <div className="font-semibold">Kan bestille genser</div>
          <div>{season.canSetSweaterSize ? 'Ja' : 'Nei'}</div>
        </div>
        <div className="px-2">
          <div className="font-semibold">Kan velge Kioskpenger</div>
          <div>{season.canSetCreditLimit ? 'Ja' : 'Nei'}</div>
        </div>

        <DialogFooter>
          <Button asChild variant="secondary">
            <Link to="..">Lukk</Link>
          </Button>
          <Button asChild variant="secondary">
            <Link to="./edit">Endre</Link>
          </Button>
          {season.active === false && (
            <Button disabled={submitting} onClick={onActivate}>
              Gjør aktiv
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
