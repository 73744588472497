import { Table } from '@/components/ui/table'

type TableLayoutProps = {
  children: React.ReactNode
}

export const TableLayout = ({ children }: TableLayoutProps) => {
  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="max-w-full overflow-x-auto">
        <Table>{children}</Table>
      </div>
    </div>
  )
}
