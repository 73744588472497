import { Navigate, Route, Routes } from 'react-router-dom'
import { Layout } from '@/components/layout'
import { Terms } from './terms'
import { NewTerm } from './new-term'
import { EditTerm } from './edit-term'

export const TermsRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Terms />}>
          <Route path="new" element={<NewTerm />} />
          <Route path=":termId/edit" element={<EditTerm />} />
        </Route>
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </Layout>
  )
}
