import { CombinedError } from 'urql'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { Alert, AlertDescription, AlertTitle } from './ui/alert'

type Props = {
  error: CombinedError | Error
}

export const QueryError = ({ error }: Props) => {
  console.error(error)

  return (
    <Alert className="my-8" variant="destructive">
      <ExclamationTriangleIcon className="h-4 w-4" />
      <AlertTitle>Whops!</AlertTitle>
      <AlertDescription>
        Kunne ikke laste innhold. Vennligst prøv på nytt. Ta kontakt om
        problemet vedvarer.
      </AlertDescription>
    </Alert>
  )
}
