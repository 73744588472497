import { config } from '@/config'
import { Button } from '@/components/ui/button'
import { StyledNavLink } from './styled-navlink'
import { useViewer } from './viewer-context-provider'

type Props = {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => {
  const viewer = useViewer()

  const handleLogout = async () => {
    await fetch(config.authSessionUrl, {
      method: 'DELETE',
      credentials: 'include',
    })
    window.location.replace('/login')
  }

  const isManager = viewer.hasAnyRole('admin', 'accounting')
  const isAdmin = viewer.hasRole('admin')

  return (
    <div className="flex flex-col h-screen">
      <div className="w-full h-14 bg-black">
        <nav className="flex items-center justify-between px-3 lg:px-6 py-3">
          <StyledNavLink to="/">Oksnøen</StyledNavLink>
          <div className="flex">
            {viewer.user && (
              <StyledNavLink to="/bookings">Bookinger</StyledNavLink>
            )}
            {isManager && <StyledNavLink to="/terms">Perioder</StyledNavLink>}
            {isManager && <StyledNavLink to="/seasons">Sesonger</StyledNavLink>}
            {isAdmin && <StyledNavLink to="/users">Brukere</StyledNavLink>}
            {isManager && (
              <StyledNavLink to="/accounting-exports">
                Fakturering
              </StyledNavLink>
            )}
          </div>
          <Button
            className="text-white"
            variant="outline"
            onClick={handleLogout}
          >
            Logg ut
          </Button>
        </nav>
      </div>

      <div className="w-full p-8 flex-grow">{children}</div>
    </div>
  )
}
