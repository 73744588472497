import { Link } from 'react-router-dom'
import { useQueryParams } from '@/hooks/use-query-params'

type Props = {
  label: string
  field: string
  value?: string
}

export function OrderDirection({ label, field, value }: Props) {
  const [queryParams] = useQueryParams()
  const current = value || queryParams.order || ''
  const [currentField, currentDirection] = current.split(':')
  const isCurrent = currentField === field
  const newDirection = currentDirection === 'ASC' && isCurrent ? 'DESC' : 'ASC'

  const search = Object.entries({
    ...queryParams,
    order: `${field}:${newDirection}`,
  })
    .map(([k, v]) => `${k}=${v}`)
    .join('&')

  return (
    <Link to={`?${search}`} className="block cursor-pointer">
      {label}
      {isCurrent && (
        <span className="pl-2 text-xs">
          {currentDirection === 'ASC' ? '▲' : '▼'}
        </span>
      )}
    </Link>
  )
}
