import { Link, Outlet } from 'react-router-dom'
import { useQuery } from 'urql'
import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { useQueryParams } from '@/hooks/use-query-params'
import { Button } from '@/components/ui/button'
import { TableLayout } from '@/components/table-layout'
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { WarningAlert } from '@/components/warning-alert'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Pagination } from '@/components/pagination'

const usersDocument = graphql(`
  query Users($input: UsersConnectionInputArgs!) {
    users(input: $input) {
      totalCount
      results {
        id
        name
        email
        phone
        active
        roles
      }
    }
  }
`)

export const Users = () => {
  const [queryParams] = useQueryParams()
  const limit = 50
  const page = Number(queryParams.page ?? 1)
  const query = queryParams.query
  const order = queryParams.order ?? 'user.name:ASC'
  const [{ data, fetching, error }, refetch] = useQuery({
    query: usersDocument,
    variables: {
      input: { limit, page, query, order },
    },
  })

  const users = data?.users.results || []

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-bold leading-loose">Brukere</h1>
        <Button asChild>
          <Link to="new">Ny bruker +</Link>
        </Button>
      </div>

      <TableLayout>
        <TableHeader>
          <TableRow>
            <TableHead>Navn</TableHead>
            <TableHead>E-post</TableHead>
            <TableHead>Telefon</TableHead>
            <TableHead>Roller</TableHead>
            <TableHead>Aktivert</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.phone}</TableCell>
              <TableCell>{user.roles.join(', ')}</TableCell>
              <TableCell>{user.active ? 'Ja' : 'Nei'}</TableCell>
              <TableCell className="py-1">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="sm">
                      <DotsHorizontalIcon className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem>
                      <Link to={`${user.id}/edit`}>Endre bruker</Link>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableLayout>

      {fetching && <Loading />}
      {error && <QueryError error={error} />}
      {users.length === 0 && !fetching && !error && (
        <WarningAlert
          title="Ingen resultater"
          description="Ingen brukere ble funnet."
        />
      )}
      {data && data.users.totalCount > limit && (
        <Pagination
          className="mt-4"
          total={data.users.totalCount}
          limit={limit}
        />
      )}

      <Outlet context={{ refetch }} />
    </>
  )
}
