import { Client, fetchExchange } from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import { authExchange } from '@urql/exchange-auth'
import { config } from '@/config'
import { refreshAuth } from './refresh-auth'

type UrqlClientOptions = {
  fetch?: WindowOrWorkerGlobalScope['fetch']
}

export function createUrqlClient(options: UrqlClientOptions = {}) {
  return new Client({
    url: config.graphqlUrl,
    requestPolicy: 'network-only',
    fetch: options.fetch,
    fetchOptions: {
      credentials: 'include',
    },
    exchanges: [
      cacheExchange({
        keys: {
          Address: () => null,
        },
      }),
      authExchange(async () => {
        return {
          addAuthToOperation(operation) {
            return operation
          },
          didAuthError(error) {
            return error.graphQLErrors.some(
              (e) => e.extensions?.code === 'UNAUTHENTICATED'
            )
          },
          async refreshAuth() {
            const didRefreshAuth = await refreshAuth()
            if (!didRefreshAuth) {
              window.location.replace('/login')
            }
          },
        }
      }),
      fetchExchange,
    ],
  })
}
