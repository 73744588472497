import { useState } from 'react'
import { useMutation } from 'urql'
import { graphql } from '@/__generated__/gql'
import { phrases } from '@/utils/phrases'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

export const uncancelBookingDocument = graphql(`
  mutation UncancelBooking($input: UncancelBookingInput!) {
    uncancelBooking(input: $input) {
      booking {
        id
        status
        reservation {
          id
          accepted
          sequence
        }
      }
    }
  }
`)

export const UncancelBookingButton = ({ bookingId }: { bookingId: string }) => {
  const [open, setOpen] = useState(false)
  const [_, uncancelBooking] = useMutation(uncancelBookingDocument)
  const { toast } = useToast()

  const onUncancelBooking = async () => {
    const { error } = await uncancelBooking({
      input: { bookingId },
    })

    if (error) {
      console.error(error)
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    toast({
      title: 'Gjenopprettet booking',
      description: 'Bookingen er nå gjenopprettet',
      variant: 'default',
    })
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary" size="sm">
          Gjenopprett
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Gjenopprett booking</DialogTitle>
          <DialogDescription>
            Er du sikker på at du vil gjenopprette denne bookingen? Er det
            venteliste vil den havne bakerst i køen.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Avbryt
            </Button>
          </DialogClose>
          <Button type="submit" onClick={onUncancelBooking}>
            Gjenopprett
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
